import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

const styles = theme => ({
  block: {
    color: 'inherit',
    padding: '15px',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
    fontSize: '12px',
  },
  left: {
    float: 'left!important',
    display: 'block',
  },
  right: {
    padding: '15px 0',
    margin: '0',
    fontSize: '14px',
    float: 'right!important',
  },
  footer: {
    bottom: '0',
    borderTop: '1px solid #e7e7e7',
    padding: '15px 100px',
    height: 80,
  },
  container: {
    flex: 1,
    backgroundColor: '#eee',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
});

const BASE_URL = 'https://www.campus-skills.com';

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href={`${BASE_URL}/administration-des-donnees`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.block}
              >
                Administration des données personnelles
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href={`${BASE_URL}?open=terms-and-conditions`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.block}
              >
                Conditions d'utilisation
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href={`${BASE_URL}?open=privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.block}
              >
                Politique de confidentialité
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; 2017 - {1900 + new Date().getYear()}{' '}
            <a href={BASE_URL} className={classes.a}>
              Campus Skills
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
