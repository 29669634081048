import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function FormDialog({ open, setOpen, sendRequest, loading }) {
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setError(false);
    if (email && !emailRegex.test(email)) {
      setError(true);
    } else {
      sendRequest(email);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Export des compétences</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Nous vous envoyons par mail cette liste, veuillez saisir votre email
        </DialogContentText>
        <TextField
          value={email}
          error={error}
          onChange={e => setEmail(e.target.value)}
          autoFocus
          required
          margin="dense"
          id="email"
          label="Addresse email"
          type="email"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={onClick} color="primary">
          {loading && <CircularProgress size={30} />}
          Valider
        </Button>
      </DialogActions>
      <DialogContent>
        <Typography variant="caption" style={{ marginTop: 20 }}>
          En validant vous acceptez nos{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.campus-skills.com?open=terms-and-conditions"
          >
            conditions générales
          </a>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default FormDialog;
