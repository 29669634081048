import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  connectStateResults,
  Configure,
} from 'react-instantsearch-dom';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import urlSync from './urlSync';
import AppBar from './components/AppBar';
import Table from './components/Table';
import Pagination from './components/Pagination';
import Select from './components/Select';
import Footer from './components/Footer';
import OnBoarding from './components/OnBoarding';
import theme from './theme';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { SnackbarProvider } from 'notistack';
import SearchBox from './components/SearchBox';

const searchClient = algoliasearch(
  'KABXREOIUQ',
  'b6bc7db581ed093ff2a47bf3942661d4'
);

const Content = connectStateResults(({ searchResults, loading }) => {
  if (loading) {
    return <CircularProgress />;
  }
  const hasResults = searchResults && searchResults.nbHits !== 0;
  if (hasResults) {
    const [hitsPerPage, changeHitPerPage] = useState(20);
    return (
      <>
        <Configure hitsPerPage={hitsPerPage} />
        <Table nbSkills={searchResults.nbHits} data={searchResults.hits} />
        <Pagination
          changeHitPerPage={changeHitPerPage}
          hitsPerPage={hitsPerPage}
          nbSkills={searchResults.nbHits}
        />
      </>
    );
  }
  return <div>Aucun résultat</div>;
});

const useStyles = makeStyles(theme => ({
  select: {
    marginTop: 30,
    marginBottom: 30,
  },
  info: {
    marginTop: 30,
  },
}));

const App = ({ searchState, createURL, onSearchStateChange }) => {
  const classes = useStyles();
  const alreadyRun = localStorage.getItem('onBoardingSeen');
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider>
        <AppBar />
        {!alreadyRun && <OnBoarding />}
        <Container>
          <InstantSearch
            searchState={searchState}
            createURL={createURL}
            onSearchStateChange={onSearchStateChange}
            searchClient={searchClient}
            indexName="skills"
          >
            <Grid
              container
              alignItems="center"
              direction="column"
              justify="center"
            >
              <Grid item xs={12} sm={8} className={classes.select}>
                <Select attribute="obligatoire" operator="or" searchable />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography align="center">Ou</Typography>
              </Grid>
              <Grid item xs={12} sm={8} className={classes.select}>
                <SearchBox />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Content />
              </Grid>
              <Typography className={classes.info}>
                <InfoIcon color="primary" />
                L'ensemble des données proviennent de{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://ec.europa.eu/esco/portal/skill"
                >
                  https://ec.europa.eu/esco/portal/skill
                </a>
              </Typography>
            </Grid>
          </InstantSearch>
        </Container>
        <Footer />
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  searchState: PropTypes.object,
  createURL: PropTypes.func,
  onSearchStateChange: PropTypes.func,
};

export default urlSync(App);
