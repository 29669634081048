import { createMuiTheme } from '@material-ui/core/styles';

const CSBleu = 'rgb(11,84,175)';
const CSOrange = '#F28B40';
const CSGrey = '#E2E2E2';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: CSBleu,
    },
    secondary: {
      main: CSOrange,
    },
    grey: {
      main: CSGrey,
    },
  },
  typography: {
    typography: {
      useNextVariants: true,
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
