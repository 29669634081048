import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import GetAppIcon from '@material-ui/icons/GetApp';
import ModalSend from './ModalSend';
import { withSnackbar } from 'notistack';

const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.common.black,
    fontSize: 16,
  },
  body: {},
}))(TableCell);

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <StyledTableCell style={{}}>Intitulé de la compétence</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const EnhancedTableToolbar = props => {
  const { selected, nbSkills, classes, enqueueSnackbar } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const numSelected = selected.length;

  const sendRequest = async email => {
    try {
      setLoading(true);
      await fetch(
        `https://app.campus-skills.com/export-skills?skills=${selected}&email=${email}`
      );
      setOpen(false);
      enqueueSnackbar('Un email vous a été envoyé', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Erreur, veuillez réessayer', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Typography color="inherit" variant="subtitle1" style={{ padding: 30 }}>
          {numSelected > 0
            ? `${numSelected} compétences sélectionnées`
            : `${nbSkills} compétences disponibles : sélectionnez en pour pouvoir
            les exporter`}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && (
          <Tooltip title="Exporter la liste">
            <IconButton onClick={() => setOpen(true)} aria-label="export">
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        )}
        <ModalSend
          open={open}
          setOpen={setOpen}
          sendRequest={sendRequest}
          loading={loading}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  nbSkills: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

const EnhancedTableToolbarStyles = withStyles(useToolbarStyles)(
  withSnackbar(EnhancedTableToolbar)
);

const useStyles = theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    padding: 20,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

function EnhancedTable({ data, classes, nbSkills }) {
  const [selected, setSelected] = React.useState([]);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.objectID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(_, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <Paper className={classes.root}>
      <EnhancedTableToolbarStyles nbSkills={nbSkills} selected={selected} />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data.length}
          />
          <TableBody>
            {data.map(n => {
              const isItemSelected = isSelected(n.objectID);
              return (
                <TableRow
                  hover
                  onClick={event => handleClick(event, n.objectID)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={n.objectID}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {n.title}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles(useStyles)(EnhancedTable);
