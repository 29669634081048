import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import TablePagination from '@material-ui/core/TablePagination';

const CustomPagination = connectPagination(
  ({
    refine,
    currentRefinement,
    hitsPerPage: rowsPerPage,
    changeHitPerPage,
    nbSkills,
  }) => (
    <TablePagination
      component="div"
      rowsPerPageOptions={[20, 50, 100]}
      count={nbSkills}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage="Compétences par page"
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} sur ${count} compétences`
      }
      page={currentRefinement - 1}
      onChangePage={(_, page) => refine(page + 1)}
      onChangeRowsPerPage={e => changeHitPerPage(e.target.value)}
    />
  )
);

export default CustomPagination;
