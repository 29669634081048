import React from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';

const steps = [
  {
    target: '#selector',
    content: 'Rechercher parmi plus de 1000 métiers',
  },
  {
    target: 'input[type="checkbox"]:first-child',
    content: 'Sélectionner une ou plusieurs compétences',
  },
  {
    target: 'button[type="button"]:first-child',
    content: 'Exporter vos compétences',
  },
];

const OnBoarding = () => {
  const handleJoyrideCallback = data => {
    const { status, action, index } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      document.querySelector('input[type="checkbox"]:first-child').click();
      localStorage.setItem('onBoardingSeen', true);
    }
    if (action === ACTIONS.UPDATE && index === 1) {
      document.querySelector('input[type="checkbox"]:first-child').click();
    }
  };
  return (
    <Joyride
      locale={{
        back: 'Retour',
        close: 'Fermer',
        last: 'Commencer',
        next: 'Suivant',
        skip: 'Passer',
      }}
      debug
      continuous
      run
      steps={steps}
      scrollToFirstStep
      disableScrolling
      spotlightClicks
      showProgress
      showSkipButton
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 2147483647,
          primaryColor: '#1356ac',
        },
      }}
    />
  );
};
export default OnBoarding;
